import $ from 'jquery';
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus';
import Main from './components/Main.vue';
const declaration = `
  <div style="text-indent:2em;">
  <p>请注意，本程序仅仅是一个小游戏，用于生成搞笑的聊天截图，与近期大热的 AI 程序 ChatGPT 完全没有关系。本程序输出的结果，用户可以利用任何一款具有开发者模式的浏览器轻易获得。</p>
  <p style="margin-top:10px">本站为纯静态网站，既不会收集用户信息，也不会向用户提供任何信息。因此，程序输出的结果完全由用户本身决定，也理应完全由用户本身负责。</p>
  <p style="margin-top:10px">但是，作为本程序的设计者，我还是想要敬告用户：不要将本程序输出的结果用于诈骗、造谣、诋毁等非法勾当。天网恢恢，肥而不腻；玩笑有度，皆大欢喜。</p>
  </div>`;
export default {
  name: 'App',
  components: {
    Main
  },
  data() {
    return {
      loading: false,
      read: false,
      adShow: false,
      seconds: 30
    };
  },
  methods: {
    notice() {
      ElNotification({
        title: '提示',
        message: '点击头像以更改它。',
        duration: 2000,
        position: 'bottom-right',
        offset: 180
      });
    },
    showAds() {
      return new Promise(resolve => {
        const that = this;
        that.adShow = true;
        let timer = setInterval(() => {
          that.seconds--;
          if (that.seconds <= 0) {
            clearInterval(timer);
            timer = null;
            that.adShow = false;
            that.seconds = 30;
            $('iframe').remove();
            resolve();
          }
        }, 1000);
      });
    }
  },
  mounted() {
    const that = this;
    const storage = window.localStorage;
    if (storage && storage['not-first']) {
      this.notice();
      return;
    }
    setTimeout(() => {
      this.read = true;
    }, 1000 * 10);
    ElMessageBox({
      title: '免责声明',
      message: declaration,
      confirmButtonText: '知道了',
      cancelButtonText: '知道了',
      dangerouslyUseHTMLString: true,
      type: 'warning',
      icon: 'none',
      customClass: 'declaration',
      showCancelButton: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      beforeClose: (action, instance, done) => {
        if (that.read) {
          if (storage) {
            storage['not-first'] = true;
          }
          done();
          that.notice();
        } else {
          ElMessage({
            message: '请至少花 10 秒钟时间阅读免责声明',
            offset: 45,
            type: 'error',
            grouping: true,
            duration: 3000
          });
        }
      }
    });
  }
};