import "core-js/modules/es.array.push.js";
import html2canvas from 'html2canvas';
import { ElMessage, ElMessageBox } from 'element-plus';
import Question from './Question.vue';
import defaultTalks from '@/assets/defaultTalks.json';
import ImageSelector from './ImageSelector.vue';
const nonEmpty = {
  message: '内容不能为空',
  offset: 45,
  type: 'error',
  grouping: true,
  duration: 1000
};
export default {
  name: 'MainPage',
  components: {
    Question,
    ImageSelector
  },
  data() {
    return {
      type: 'q',
      content: '',
      contents: [],
      imgUrl: '',
      imgVisible: false,
      randomNumber: 0,
      modifyVisible: false,
      modifiedContent: '',
      modifyIndex: -1,
      isGenerating: false,
      userAvatar: ''
    };
  },
  computed: {
    typeText() {
      return this.type === 'q' ? '问题' : '回答';
    },
    modifyType() {
      return this.modifyIndex === -1 ? 'q' : this.contents[this.modifyIndex].type;
    },
    modifyTypeText() {
      return this.modifyType === 'q' ? '问题' : '回答';
    }
  },
  methods: {
    clearContent() {
      if (this.contents.length === 0) {
        return;
      }
      const that = this;
      ElMessageBox.confirm('确认要清空所有对话？', '警告', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        'close-on-click-modal': false,
        'close-on-press-escape': false
      }).then(() => {
        that.contents = [];
        that.type = 'q';
      }).catch(() => {});
    },
    changeModifyIndex(index) {
      this.modifyIndex = index;
      this.modifiedContent = this.contents[index].content;
      this.modifyVisible = true;
    },
    deleteContent(index) {
      if (this.contents.length % 2) {
        this.type = this.type === 'q' ? 'a' : 'q';
      }
      this.contents.splice(index, 2);
    },
    modifyContent() {
      if (this.modifiedContent.trim().length === 0) {
        ElMessage(nonEmpty);
        return;
      }
      this.contents[this.modifyIndex].content = this.modifiedContent;
      this.modifiedContent = '';
      this.modifyVisible = false;
      this.modifyIndex = -1;
    },
    addContent() {
      const that = this;
      if (this.content.trim().length === 0) {
        ElMessage(nonEmpty);
        return;
      }
      this.contents.push({
        content: that.content,
        type: that.type
      });
      this.type = this.type === 'q' ? 'a' : 'q';
      this.content = '';
    },
    getUserAvatar(data) {
      this.userAvatar = data;
      if (this.imgVisible) {
        setTimeout(() => {
          this._getImg();
        });
      }
    },
    avatarClick() {
      ElMessageBox.confirm('看广告可以自定义头像~', '恰饭时间到', {
        confirmButtonText: '看吧',
        cancelButtonText: '不看',
        type: 'info',
        'append-to': 'body',
        'close-on-click-modal': false,
        'close-on-press-escape': false
      }).then(() => {
        this.$root.showAds().then(() => {
          this.$refs['image-selector'].showImageSelector = true;
        }).catch(() => {});
      }).catch(() => {});
    },
    getImg() {
      if (this.contents.length === 0) {
        ElMessage({
          message: '未添加对话',
          offset: 45,
          type: 'error',
          grouping: true,
          duration: 1000
        });
        return;
      }
      if (this.isGenerating) {
        return;
      }
      this.isGenerating = true;
      this.$refs['question'].map(item => {
        item.quickHideControlBtns();
      });
      this._getImg(() => {
        this.imgVisible = true;
        this.isGenerating = false;
      }, () => {
        this.isGenerating = false;
      });
    },
    _getImg(thenCallback, catchCallback) {
      this.$root.loading = true;
      const dom = document.getElementById('talks-container');
      const scale = window.devicePixelRatio;

      // 传入节点原始宽高
      const width = dom.offsetWidth;
      const height = dom.offsetHeight;

      // html2canvas配置项
      const ops = {
        scale,
        width,
        height,
        useCORS: true,
        allowTaint: false
      };
      html2canvas(dom, ops).then(canvas => {
        const form = this.imgUrl;
        this.imgUrl = canvas.toDataURL('image/jpg');
        console.log(form === this.imgUrl);
        if (thenCallback) {
          thenCallback();
        }
        this.$root.loading = false;
      }).catch(() => {
        if (catchCallback) {
          catchCallback();
        }
        this.$root.loading = false;
      });
    }
  },
  mounted() {
    this.randomNumber = Math.floor(Math.random() * (71 - 1 + 1)) + 1;
    const randomTalk = defaultTalks[Math.floor(Math.random() * defaultTalks.length)];
    this.contents.push({
      type: 'q',
      content: randomTalk.question
    }, {
      type: 'a',
      content: randomTalk.answer
    });
  }
};