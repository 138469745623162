import { ElMessage } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';
export default {
  name: 'ImageSelector',
  components: {
    Plus
  },
  data() {
    return {
      imageUrl: '',
      showImageSelector: false
    };
  },
  methods: {
    confirm() {
      if (this.imageUrl) {
        this.$emit('chooseImage', this.imageUrl);
        this.imageUrl = '';
        this.showImageSelector = false;
      }
    },
    close(done) {
      this.imageUrl = '';
      if (done) {
        done();
      } else {
        this.showImageSelector = false;
      }
    },
    onImageChange(uploadFile) {
      const type = uploadFile.raw.type;
      if (type.split('/')[0] !== 'image') {
        ElMessage({
          message: '请选择图片',
          offset: 45,
          type: 'error',
          grouping: true,
          duration: 1000
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(uploadFile.raw);
        reader.onload = e => {
          this.imageUrl = e.target.result;
        };
      }
    }
  }
};