import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2f951dd7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "ads"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "timer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Main = _resolveComponent("Main");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives(_createVNode(_component_Main, null, null, 512), [[_directive_loading, $data.loading]]), $data.adShow ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], item => {
    return _createElementVNode("iframe", {
      frameborder: "0",
      key: item,
      src: './ads/ad' + item + '.html'
    }, null, 8, _hoisted_2);
  }), 64)), _createElementVNode("div", _hoisted_3, _toDisplayString($data.seconds) + " 秒后广告会自动消失", 1)])) : _createCommentVNode("", true)], 64);
}