import $ from 'jquery';
import { ElMessageBox } from 'element-plus';
export default {
  name: 'QuestionTemplate',
  props: {
    type: String,
    content: String,
    index: Number,
    randomNumber: Number,
    userAvatar: String
  },
  data() {
    return {
      shadow: false
    };
  },
  computed: {
    typeText() {
      return this.type === 'q' ? '问题' : '回答';
    },
    oppositeText() {
      return this.type === 'a' ? '问题' : '回答';
    },
    answer() {
      return this.type === 'a' ? this.content.split('\n') : [];
    },
    imageUrl() {
      return this.userAvatar ? this.userAvatar : `./avatars/avatar(${this.randomNumber}).png`;
    }
  },
  methods: {
    avatarClick() {
      this.$emit('avatarClick');
    },
    changeModifyIndex() {
      this.$emit('changeModifyIndex', this.index);
    },
    deleteContent() {
      const that = this;
      ElMessageBox.confirm(`确认要删除此${that.typeText}？请注意，其对应的${that.oppositeText}也会被删除。`, '警告', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        'close-on-click-modal': false,
        'close-on-press-escape': false
      }).then(() => {
        that.$emit('deleteContent', this.type === 'q' ? this.index : this.index - 1);
      }).catch(() => {});
    },
    hideControlBtns() {
      const that = this;
      $(that.$refs['control-btns']).animate({
        opacity: 0
      }, null, () => {
        that.shadow = false;
      });
    },
    showControlBtns() {
      this.shadow = true;
      $(this.$refs['control-btns']).animate({
        opacity: 1
      });
    },
    quickHideControlBtns() {
      $(this.$refs['control-btns']).css('opacity', 0);
      this.shadow = false;
    }
  }
};